import Axios from "@app/api/axios";
import config from "@src/config";

const getUserService = (social_security_number, user_role, user_id) => {
  return Axios.post(`${config.BASE_URL}users`, {
    social_security_number,
    user_role,
    user_id,
  });
};

export default getUserService;
