import React, { Suspense } from "react";
import { IntlProvider } from "react-intl";
import ErrorBoundary from "@components/ErrorBoundary";
import AppRoute from "./AppRoute";
import messages_en from "@translation/en.json";
import messages_sv from "@translation/sv.json";
import { Provider } from "react-redux";
import { store } from "@app/store";

import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "@sass/app.scss";

const messages = {
  en: messages_en,
  sv: messages_sv,
};
const applanguage = "sv";

function App() {
  return (
    <div className="App">
      <ErrorBoundary>
        <Suspense fallback={<div />}>
          <Provider store={store}>
            <IntlProvider
              defaultLocale="sv"
              locale={applanguage}
              messages={messages[applanguage]}
            >
              <AppRoute />
            </IntlProvider>
          </Provider>
        </Suspense>
      </ErrorBoundary>
    </div>
  );
}

export default App;
