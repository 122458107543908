export const breakpoints = {
  mobile: 640,
  mobileLandscape: 576,
  tablet: 832,
  tabletPotrait: 944,
  tabletLandscape: 1023,
  dekstopSmall: 960,
  desktopMedium: 1200,
  desktopLarge: 1500,
  desktopWide: 1800,
};
