import { createSlice } from "@reduxjs/toolkit";

const surveySlice = createSlice({
  name: "surveyData",

  initialState: {
    surveyStatus: 0,
  },

  reducers: {
    setSurveyStatus: (state, action) => {
      const { payload } = action;
      state.surveyStatus = payload;
    },
  },
});

export const {
  actions: { setSurveyStatus },
  reducer: surveyReducer,
} = surveySlice;
