import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import Config from "../config";

const configureSentry = () => {
  if (Config.sentryURL) {
    Sentry.init({
      dsn: Config.sentryURL,
      integrations: [new BrowserTracing()],
      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 1.0,
    });
  }
};

export default configureSentry;
