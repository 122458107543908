import Config from "@src/config";
import { setupCache } from "axios-cache-adapter";

const { api = {} } = Config;
const { maxAge = 0 } = api;

const cache = setupCache({
  maxAge: maxAge, // defaulted not cache
  exclude: {
    query: false,
    methods: ["patch", "put", "delete"],
  },
  debug: false,
  invalidate: async (config, request) => {
    if (request.clearCacheEntry) {
      await config.store.removeItem(config.uuid);
    }
  },
});

export default cache;
