export const BASIC = "BASIC";

export const GRID = "GRID";

export const BUTTON = "BUTTON";

export const RATING = "RATING";

export const LINK = "LINK";

export const PROGRESSBAR = "PROGRESSBAR";

export const PERCENT = "PERCENT";

export const LABEL = "LABEL";

export const RISK = "risk";

export const PRICE = "PRICE";

export const LOW = "Low";

export const HIGH = "High";

export const MEDIUM = "Medium";

export const COLSELECTOR = "colSelector";

export const BUY = "Köp";

export const SELL = "Sälj";

export const CURRENCY_CODE = "kr";

export const MULTIPLE = "multiple";

export const CATEGORY = "CATEGORY";

export const WEIGHT = "WEIGHT";

export const QUANTITY = "QUANTITY";
