import React from "react";
import Proptypes from "prop-types";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { compose, space, position, layout, shadow } from "styled-system";
import { Sidebar as PRStyledSidebar } from "primereact/sidebar";
import { breakpoints } from "@utils/breakpoints";
import Div from "@components/Div";
import Logo from "@assets/logo.svg";
import MenuContent from "./MenuContent";
import SurveyMenuContent from "./SurveyMenuContent";
import { NAV_BOTTOM_MENU_ITEMS } from "@utils/constant";

const { mobile, tabletLandscape } = breakpoints;

const breakpoint = {
  tablet: `(min-width: ${mobile}px) and (max-width: ${tabletLandscape}px)`,
  desktop: `(min-width: ${tabletLandscape}px)`,
};

const SideBar = styled(PRStyledSidebar)`
  ${compose(space, position, layout, shadow)};
  width: 190px !important;
  background-color: var(--primary);
  overflow: hidden;
  .p-sidebar-header {
    padding: var(--md-spacing);
    padding-left: var(--sm-spacing);
    .p-sidebar-icon {
      display: flex !important;
      left: 206px;
      top: var(--md-spacing);
      position: fixed !important;
      border-radius: 0;
      background-color: var(--white);
      color: var(--text);
      border-radius: var(--border-radii-regular);
      span {
        font-size: var(--fs-micro);
        font-weight: var(--black-weight);
      }
    }
    .p-sidebar-close:enabled:hover,
    .p-sidebar-icon:enabled:hover {
      background-color: var(--white) !important;
    }
    .p-sidebar-close:focus,
    .p-sidebar-icon:focus {
      box-shadow: none !important;
    }
  }
  .p-sidebar-content {
    padding: 0px;
    overflow: inherit;
  }
  display: block;
  .p-sidebar-header {
    @media ${breakpoint.desktop} {
      .p-sidebar-icon {
        display: none !important;
      }
    }
  }
`;

const Sidemenu = ({ display, onClose = () => {}, visibility = false }) => {
  const { sidebarMenuItem = [] } = useSelector((state) => state.sidebarReducer);
  const navMenuItems = sidebarMenuItem.map((obj) => obj);
  const navBottomMenuItems = NAV_BOTTOM_MENU_ITEMS;

  const mobileHeader = (
    <Div
      height="auto !important"
      width={1}
      pb="var(--xl-spacing)"
      pt="var(--regular-spacing)"
    >
      <img src={Logo} alt="image" />
    </Div>
  );

  return (
    <SideBar
      visible={visibility}
      boxShadow="none"
      fullScreen="true"
      showCloseIcon
      icons={mobileHeader}
      breakpoint={breakpoint}
      onHide={onClose}
      display={display}
    >
      <Div
        display="flex"
        justifyContent="space-between"
        flexDirection="column"
        height="calc(100vh - 112px)"
      >
        <SurveyMenuContent navItems={navMenuItems} />
        <MenuContent navItems={navBottomMenuItems} />
      </Div>
    </SideBar>
  );
};

Sidemenu.propTypes = {
  config: Proptypes.object,
  restProps: Proptypes.object,
  display: Proptypes.array,
  onClose: Proptypes.func,
  visibility: Proptypes.bool,
};

export default Sidemenu;
