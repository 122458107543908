import React from "react";
import PropTypes from "prop-types";
import { useNavigate, Link as PRLink } from "react-router-dom";
import styled from "styled-components";
import Div from "@components/Div";
import Icon from "@components/Icon";
import { BodyText } from "@components/Text";
import { ROUTES } from "@utils/constant";

const Link = styled(PRLink)`
  text-decoration: none;
`;

export const MenuItem = styled(Div)`
  width: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
  background: ${({ isActive }) =>
    isActive ? "var(--primary-hover)" : "var(--primary)"};
  border-left: ${({ isActive }) =>
    isActive ? "4px solid var(--text)" : "4px solid var(--transparent)"};
  padding: 2px var(--regular-spacing);
  &:hover {
    border-left: 4px solid var(--text);
    background: var(--primary-hover);
  }
`;

export const StyledDiv = styled(Div)`
  padding-top: var(--md-spacing);
  border-top: 1px solid var(--border-line);
  }
`;

const MenuContent = ({ navItems = [] }) => {
  const navigate = useNavigate();

  const handleMenuClicked = (item) => {
    if (item.redirectTo === ROUTES.LOGIN.URL) {
      localStorage.clear();
      window.location.reload();
      navigate(ROUTES.LOGIN.URL);
    }
    if (item.redirectTo === ROUTES.SEARCH.URL) {
      navigate(ROUTES.SEARCH.URL);
      window.location.reload();
    }
  };

  const handleExternalPageRedirection = (url) => () => {
    window.open(url);
  };

  const menuItem = (item) => {
    return (
      <MenuItem px="var(--regular-spacing)" mb="var(--regular-spacing)">
        <Icon
          name={item.icon}
          pr="var(--fs-mini)"
          fontSize="var(--fs-h5)"
          color="var(--text)"
        />
        <BodyText>{item.label}</BodyText>
      </MenuItem>
    );
  };

  const mainContent = (items = []) =>
    items.map((item) => {
      const { label = "" } = item;
      if (item && item.isExternalPage) {
        return (
          <Link
            width={1}
            key={label}
            onClick={handleExternalPageRedirection(item.redirectTo)}
          >
            {menuItem(item)}
          </Link>
        );
      } else {
        return (
          <Link
            width={1}
            key={label}
            to={item.redirectTo}
            onClick={handleMenuClicked.bind(this, item)}
          >
            {menuItem(item)}
          </Link>
        );
      }
    });

  return <StyledDiv width="inherit">{mainContent(navItems)}</StyledDiv>;
};

MenuContent.propTypes = {
  navItems: PropTypes.array,
};

export default MenuContent;
