import React, { useEffect } from "react";
import AuthGuard from "./AuthGuard";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import Div from "@components/Div";
import Sidebar from "@components/Sidebar/Sidemenu";
import Header, { Button } from "@components/Header/Header";
import { H1, H3 } from "@components/Heading";
import { HEADER_MENU_ITEMS, AWS_CREDENTIALS } from "@utils/constant";
import Icon from "@components/Icon";
import styled from "styled-components";
import { breakpoints } from "@utils/breakpoints";
import { getAWSCredentials } from "@utils/utils";
import authRefreshService from "@app/services/auth/authRefreshservice";
import AWS from "aws-sdk";

const { tabletLandscape, desktopLarge } = breakpoints;

const breakpoint = {
  tablet: `(min-width: ${tabletLandscape}px) and (max-width: ${desktopLarge}px)`,
};

const StyledDiv = styled(Div)`
  @media ${breakpoint.tablet} {
    max-width: calc(98vw - 190px);
  }
`;

const UserLayout = ({ children }) => {
  const navigate = useNavigate();

  const { headerTitle = "", isDetailPage = "" } = useSelector(
    (state) => state.headerReducer
  );
  const storage = window.localStorage;

  useEffect(() => {
    const cognitoidentity = new AWS.CognitoIdentity({ region: "eu-north-1" });
    const setLocalStorage = (key, value) => {
      return storage.setItem(key, JSON.stringify(value));
    };

    const refreshAuth = async () => {
      try {
        getAWSCredentials().then(async (aws) => {
          const response = await authRefreshService(aws);
          const { epoch, Token = "", IdentityId = "" } = response;
          setLocalStorage("bfs-epoch", epoch);
          let params = {
            IdentityId: IdentityId,
            Logins: {
              "cognito-identity.amazonaws.com": Token,
            },
          };

          cognitoidentity.getCredentialsForIdentity(params, (err, data) => {
            if (err) {
              //catch error
            } else {
              const { Credentials } = data;
              const { AccessKeyId, SecretKey, SessionToken } = Credentials;

              setLocalStorage(AWS_CREDENTIALS, {
                accessKeyId: AccessKeyId,
                secretAccessKey: SecretKey,
                sessionToken: SessionToken,
              });
            }
          });
        });
      } catch (e) {
        //catch the error
      }
    };
    refreshAuth();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <AuthGuard>
      <Div display="flex" minHeight="100vh" width={1} bg="var(--bg-white)">
        <Sidebar
          display={[
            "none !important",
            "none !important",
            "none !important",
            "flex !important",
          ]}
          visibility={true}
        />
        <StyledDiv
          maxWidth={["100%", "100%", "100%", "1250px"]}
          width={1}
          position="absolute"
          left={[0, 0, 0, 190]}
        >
          <Header menuItems={HEADER_MENU_ITEMS} />

          <Div
            mt="var(--md-spacing)"
            mx="var(--md-spacing)"
            mb="var(--xs-spacing)"
            alignItems="center"
            display={["flex", "none", "none", "none"]}
          >
            {isDetailPage && (
              <>
                <Button
                  mr="var(--md-spacing)"
                  onClick={handleGoBack}
                  $isDetailedPage
                >
                  <Icon
                    className="icon ph-arrow-left-bold"
                    color="var(--text)"
                    fontSize="var(--fs-h5)"
                  />
                </Button>
                <H3>{headerTitle}</H3>
              </>
            )}
            {!isDetailPage && <H1>{headerTitle}</H1>}
          </Div>
          <Div mb={["100px", "100px", 0, 0]}>{children}</Div>
        </StyledDiv>
      </Div>
    </AuthGuard>
  );
};

UserLayout.propTypes = {
  children: PropTypes.node,
  isWizard: PropTypes.bool,
};
export default UserLayout;
