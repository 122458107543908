import React from "react";
import { Navigate } from "react-router-dom";
import PropTypes from "prop-types";
import { ROUTES } from "@utils/constant";
import { isAuthenticated } from "../utils/utils";

// For routes that can only be accessed by authenticated users
const AuthGuard = ({ children }) => {
  if (!isAuthenticated()) {
    localStorage.clear();
    return <Navigate to={ROUTES.LOGIN.URL} replace />;
  }

  return children;
};

AuthGuard.propTypes = {
  children: PropTypes.node,
};
export default AuthGuard;
