import styled from "styled-components";
import {
  compose,
  space,
  layout,
  color,
  typography,
  display,
  flexbox,
} from "styled-system";
import { getColor } from "@utils/utils";

export const BodyText = styled("span")`
  ${compose(space, color, layout, typography, display, flexbox)};
  color: ${(props) => getColor(props)};
  font-weight: var(--normal-weight);
  font-size: var(--fs-milli);
  line-height: var(--lh-base);
  letter-spacing: 0.08px;
  word-break: ${({ useWordBreak }) => (useWordBreak ? "break-word" : "normal")};
  cursor: ${({ useCursorPointer }) =>
    useCursorPointer ? "pointer" : "inherit"};
`;

export const BodyTextSemibold = styled(BodyText)`
  ${compose(space, color, typography)};
  font-weight: var(--semibold-weight);
  word-break: ${({ useWordBreak }) => (useWordBreak ? "break-word" : "normal")};
`;

export const BodyTextBold = styled(BodyText)`
  ${compose(space, color, typography)};
  font-weight: var(--bold-weight);
  cursor: ${({ useCursorPointer }) =>
    useCursorPointer ? "pointer" : "inherit"};
`;

export const SubtitleText = styled(BodyText)`
  ${compose(color, layout, typography)};
  font-size: var(--fs-base);
  line-height: var(--lh-milli);
  letter-spacing: 0.09px;
  cursor: ${({ useCursorPointer }) =>
    useCursorPointer ? "pointer" : "inherit"};
`;

export const SubtitleTextSemibold = styled(BodyTextSemibold)`
  ${compose(color)};
  font-size: var(--lh-micro);
  line-height: var(--lh-base);
  letter-spacing: 0.07px;
`;

export const CaptionText = styled(BodyText)`
  ${compose(space, color, typography, layout, flexbox, display)};
  color: ${(props) => getColor(props)};
  cursor: ${({ useCursorPointer }) =>
    useCursorPointer ? "pointer" : "inherit"};
  font-size: var(--fs-micro);
  line-height: var(--lh-micro);
  word-break: ${({ useWordBreak }) => (useWordBreak ? "break-word" : "normal")};
`;

export const CaptionSemiboldText = styled(CaptionText)`
  ${compose(color, typography)};
  font-weight: var(--semibold-weight);
  line-height: var(--lh-h5);
  overflow: ${({ textTruncate }) => (textTruncate ? "hidden" : "none")};
  display: ${({ textTruncate }) => (textTruncate ? "-webkit-box" : "block")};
  -webkit-line-clamp: ${({ textTruncate }) => (textTruncate ? "3" : "0")};
  -webkit-box-orient: ${({ textTruncate }) =>
    textTruncate ? "vertical" : "none"};
`;

export const OverlineText = styled(BodyText)`
  font-size: var(--fs-micro);
  line-height: var(--lh-base);
`;
