import { aws4Interceptor } from "aws4-axios";
import { AwsClient } from "aws4fetch";
import { APPLICATION_NAME, TITLE_DIVIDER } from "@utils/constant";
import RoutesList from "@shared/RoutesList";
import { RISK, LOW, HIGH, MEDIUM } from "@components/DataTable/Constants";
import { decode } from "jsonwebtoken";

export const addHyphenToNumber = (number, position) => {
  let value = number || "";
  const regEx = new RegExp(`(\\d{${position}})`, "g");
  value = value.replace(/([^0-9 -\s])/gm, "").replace(/\s+/g, "");
  value = value && value.replace(/([^0-9 -\s])/gm, "").replace(/\s+/g, "");
  if (value && (value.length > position + 1 || value.length < position + 1)) {
    value = value.replace(/-/g, "");
  }
  if (value && value.length > position && value.indexOf("-") !== position) {
    value = value.replace(regEx, "$1-");
  }
  return value;
};

export const setTitle = () => {
  const routePath = window.location.pathname.split("/");
  const routePathName = `/${routePath[routePath.length - 1]}`;
  const route = RoutesList.find((obj) => obj.url.includes(routePathName));
  if (route) {
    document.title = APPLICATION_NAME + TITLE_DIVIDER + route.title;
  }
};

export const isAuthenticated = () => {
  const credentails = localStorage.getItem("aws-credentials");
  const token = localStorage.getItem("Token");
  const decodedToken = decode(token);
  const { exp: epoch = 0 } = decodedToken || {};
  const date = new Date().getTime() / 1000;
  return credentails && date < epoch;
};

export const getLabelColor = (value) => {
  switch (value) {
    case RISK:
      return "var(--alert)";
    case LOW:
      return "var(--success)";
    case MEDIUM:
      return "var(--primary)";
    case HIGH:
      return "var(--alert)";
    default:
      return "var(--primary)";
  }
};

export const parseQueryParams = (queryParam = []) => {
  let queries;
  if (queryParam.length > 0) {
    queries = queryParam
      .map((obj) => {
        if (obj[1] !== undefined && obj[1] !== null) {
          return obj.join("=");
        }
        return undefined;
      })
      .filter((o) => o)
      .join("&");
  }
  return `?${queries}`;
};

export const numberFormat = (num = "") => {
  return num
    ? Number(num)
        .toFixed(2)
        .replaceAll(".", ",")
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ")
    : 0;
};

export const numberFormatWithoutDecimal = (num = "") => {
  return num
    ? Number(num)
        .toFixed(0)
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ")
    : 0;
};

export const removeDecimals = (num = 0) => {
  return num ? Number(num).toFixed(0) : num;
};

export const getAWSCredentials = async () => {
  return await new Promise((resolve) => {
    const awsCredentials = JSON.parse(localStorage.getItem("aws-credentials"));
    if (awsCredentials) {
      const { accessKeyId, secretAccessKey, sessionToken } = awsCredentials;

      const aws = new AwsClient({
        accessKeyId,
        secretAccessKey,
        sessionToken,
        region: "eu-north-1",
        service: "execute-api",
      });
      if (aws) {
        resolve(aws);
      } else {
        localStorage.clear();
        window.location = "/login";
        // reject(aws);
      }
    } else {
      // reject(awsCredentials);
      localStorage.clear();
      window.location = "/login";
    }
  });
};

export const getAwsCredentialsForAxios = () => {
  const customCredentialsProvider = {
    getCredentials: async () => {
      const awsCredentials = JSON.parse(
        localStorage.getItem("aws-credentials")
      );
      const {
        accessKeyId = "",
        secretAccessKey = "",
        sessionToken = "",
      } = awsCredentials || {};

      return Promise.resolve({
        accessKeyId: accessKeyId,
        secretAccessKey: secretAccessKey,
        sessionToken: sessionToken,
      });
    },
  };

  return new aws4Interceptor(
    {
      region: "eu-north-1",
      service: "execute-api",
    },
    customCredentialsProvider
  );
};

export const getLastValueInArray = (values) => {
  return values.length ? values[values.length - 1].value : "";
};

export const getBrickIdOfUser = (details) => {
  const { BrickId = "" } = details ? details[0] : {};
  return BrickId;
};

const replaceNaN = (value) => {
  return value !== "NaN" ? Number(value) : 0;
};

export const convertFundReturnsToNumber = (funds = []) => {
  return funds.map((o) => {
    const { returns = {} } = o;
    const { week = 0, month = 0, halfYear = 0, year = 0 } = returns;
    const newReturns = {};
    newReturns.week = replaceNaN(week);
    newReturns.month = replaceNaN(month);
    newReturns.halfYear = replaceNaN(halfYear);
    newReturns.year = replaceNaN(year);
    newReturns["3year"] = replaceNaN(returns && returns["3year"]);
    newReturns["5year"] = replaceNaN(returns && returns["5year"]);
    return { ...o, returns: newReturns };
  });
};

export const getColor = (props) => {
  const { secondary, alert, white } = props;
  switch (true) {
    case secondary:
      return "var(--blue-light)";
    case alert:
      return "var(--alert)";
    case white:
      return "var(--white)";
    default:
      return "var(--text)";
  }
};

export const parseHoldingsFromFiveYears = (data, value) => {
  return data && data.length > value ? data.slice(-Number(value)) : data;
};

export const calulatePercentOfHoldingsFromFiveYears = (
  data = [],
  count = 0
) => {
  const totalValues = data.length ? data.slice(-Number(count) - 1) : [];
  const values = data.length > count ? data.slice(-Number(count)) : data;
  const startValue = totalValues.length ? Number(totalValues[0].value) : 0;
  return values.map((o) => {
    const { date, value } = o;
    const percentage = Number(value) - startValue;
    return { date, value: Number(percentage).toFixed(2) };
  });
};
