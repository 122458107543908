import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { PublicRoutes, PrivateRoutes } from "@shared/RoutesList";
import UserLayout from "./layout/UserLayout";
import Layout from "./layout/Layout";
import { useEffect } from "react";
import getAdvisor from "@app/services/users/getAdvisor";
import { useDispatch } from "react-redux";

const AppRoute = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    getAdvisor(dispatch);
  }, [dispatch]);

  return (
    <Router>
      <Routes>
        {PrivateRoutes.map(
          ({ component: Component, showSidemenu, path }, index) => (
            <Route
              key={`private-${index}`}
              path={path}
              element={
                showSidemenu ? (
                  <UserLayout showSidemenu={showSidemenu}>
                    <Component />
                  </UserLayout>
                ) : (
                  <Layout>
                    <Component />
                  </Layout>
                )
              }
            ></Route>
          )
        )}
        {PublicRoutes.map(({ component: Component, path }, index) => (
          <Route
            path={path}
            key={`public-${index}`}
            element={
              <Layout>
                <Component />
              </Layout>
            }
          ></Route>
        ))}
      </Routes>
    </Router>
  );
};
export default AppRoute;
