import axios from "axios";
import Config from "@src/config";
import cache from "./axiosCacheAdapter";

const Axios = axios.create({
  baseURL: Config.BASE_URL,
  adapter: cache.adapter,
});

export default Axios;
