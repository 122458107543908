import React from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import Div from "@components/Div";
import { MenuItem } from "./MenuContent";
import { BodyText } from "@components/Text";
import { setPageTitle } from "@app/reducers/headerReducer";
import { setPageName } from "@app/reducers/sidebarReducer";

const MenuContent = ({ navItems = [] }) => {
  const dispatch = useDispatch();

  const handleExternalPageRedirection = (item) => () => {
    const pageName = item.name;
    const pageTitle = item.title;
    dispatch(setPageName(pageName));
    dispatch(setPageTitle(pageTitle));
  };

  const { headerTitle = "" } = useSelector((state) => state.headerReducer);

  const menuItem = (item) => {
    return (
      <MenuItem
        px="var(--regular-spacing)"
        mb="var(--regular-spacing)"
        isActive={item.title === headerTitle}
      >
        <BodyText>{item.title}</BodyText>
      </MenuItem>
    );
  };

  const mainContent = (items = []) =>
    items.map((item) => {
      const { name = "" } = item;
      return (
        <Div width={1} key={name} onClick={handleExternalPageRedirection(item)}>
          {menuItem(item)}
        </Div>
      );
    });

  return (
    <Div width="inherit" className="topMenu">
      {mainContent(navItems)}
    </Div>
  );
};

MenuContent.propTypes = {
  navItems: PropTypes.array,
};

export default MenuContent;
