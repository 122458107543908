import styled from "styled-components";
import {
  compose,
  space,
  color,
  typography,
  border,
  layout,
} from "styled-system";

const LabelDefault = styled("label")`
  ${compose(space, color, typography, border, layout)};
  color: var(--white);
  font-size: var(--fs-micro);
  line-height: var(--lh-micro);
  display: flex;
  justify-content: center;
  border-radius: var(--border-radii-large);
  max-width: 95px;
  padding: var(--xs-spacing) 0;
  align-items: center;
`;

export default LabelDefault;
