import getUserService from "@app/services/users/getUserService";

import { BFS_PN, USER_ROLE } from "@utils/constant";

const getAdvisor = async () => {
  const pn = localStorage.getItem(BFS_PN);
  if (pn) {
    const SSN = pn.toString();
    const formatedSSN = SSN.match(/.{1,8}/g).join("-");
    const { data: advisor } = await getUserService(
      "",
      [USER_ROLE.ADMIN, USER_ROLE.ADVISOR],
      formatedSSN
    );
    return advisor;
  }
};

export default getAdvisor;
