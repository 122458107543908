import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
  name: "authReducer",
  initialState: {
    user: {},
    isLogout: false,
  },
  reducers: {
    setUser: (state, action) => {
      const { payload } = action;
      state.user = payload;
    },
  },
});

export const {
  actions: { setUser },
  reducer: authReducer,
} = authSlice;
