import { createSlice } from "@reduxjs/toolkit";

const headerTitleSlice = createSlice({
  name: "headerTitle",

  initialState: {
    headerTitle: "",
  },

  reducers: {
    setPageTitle: (state, action) => {
      const { payload } = action;
      state.headerTitle = payload;
    },
  },
});

export const {
  actions: { setPageTitle },
  reducer: headerReducer,
} = headerTitleSlice;
