import React from "react";
import PropTypes from "prop-types";
import Div from "@components/Div";

const Layout = ({ children }) => {
  return (
    <Div
      display="flex"
      minHeight="100vh"
      alignItems="center"
      flexDirection="column"
      width={1}
      bg="var(--bg-white)"
    >
      {children}
    </Div>
  );
};

Layout.propTypes = {
  children: PropTypes.node,
};
export default Layout;
