import Axios from "@app/api/axios";
import config from "@src/config";
import { getAwsCredentialsForAxios } from "@utils/utils";

function handleExpiredAuthentication(error) {
  if (401 === error.response.status || 403 === error.response.status) {
    localStorage.clear();
    window.location = "/login";
  } else {
    return Promise.reject(error);
  }
}

const configureAxios = () => {
  Axios.defaults.baseURL = config.BASE_URL;
  Axios.defaults.headers.get["Accept"] = "application/json";
  Axios.defaults.headers.post["Accept"] = "application/json";
  Axios.defaults.headers.put["Accept"] = "application/json";
  Axios.defaults.headers["Content-Type"] = "application/json";
  Axios.interceptors.request.use((http) => {
    delete http.headers["Host"];
    delete http.headers["Content-Length"];
    return http;
  });
  Axios.interceptors.request.use(getAwsCredentialsForAxios());
  Axios.interceptors.response.use(undefined, handleExpiredAuthentication);
};
export default configureAxios;
