import { createSlice } from "@reduxjs/toolkit";

const casesSlice = createSlice({
  name: "casesData",

  initialState: {
    casesList: [],
  },

  reducers: {
    setCasesList: (state, action) => {
      const { payload } = action;
      state.casesList = payload;
    },
  },
});

export const {
  actions: { setCasesList },
  reducer: casesReducer,
} = casesSlice;
