import styled from "styled-components";
import { Button as PRButton } from "primereact/button";
import {
  compose,
  space,
  position,
  flexbox,
  layout,
  typography,
  color,
  border,
  display,
} from "styled-system";
import { breakpoints } from "@utils/breakpoints";

const { tablet } = breakpoints;

const mobileWidth = `(max-width: ${tablet}px)`;

export const Button = styled(PRButton)`
  ${compose(layout, flexbox, display, space, color, border, typography)};
  padding: ${({ size }) =>
    size === "small"
      ? "var(--regular-spacing) var(--sm-spacing)"
      : "var(--sm-spacing)"};
  border-radius: var(--border-radii-regular) !important;
  font-weight: var(--bold-weight);
  transition: none;
  display: flex;
  justify-content: ${({ $justifyContent = "space-between" }) =>
    $justifyContent};
  align-items: center;
  overflow: visible;

  &:focus {
    box-shadow: none !important;
  }

  @media ${mobileWidth} {
    width: 100%;
  }
`;

export const PrimaryButton = styled(Button).attrs({
  className: "p-button-rounded",
})`
  ${compose(space, position, layout, typography, flexbox)};
  color: var(--white);
  background-color: var(--blue-light);
  border-color: var(--blue-light);
  opacity: 1 !important;

  &:hover {
    background-color: var(--blue) !important;
    border-color: var(--blue) !important;
  }

  &.icon {
    color: var(--white);
  }
`;

export const SecondaryButton = styled(Button).attrs({
  className: "s-button-rounded",
})`
  ${compose(space, position, layout, typography)};
  background-color: var(--grey-light);
  border-color: var(--grey-light);
  color: var(--blue-light);

  &:hover {
    background-color: var(--grey-light) !important;
    border-color: var(--grey-light) !important;
    color: var(--blue) !important;
  }

  &.icon {
    color: var(--blue-light);
  }
`;

export const AlternateButton = styled(Button).attrs({
  className: "alt-button-rounded",
})`
  ${compose(space, position, layout, color, typography, flexbox)};
  background-color: var(--white);
  border-color: var(--grey-light);
  color: var(--blue-light);

  &:hover {
    background-color: var(--white) !important;
    border-color: var(--blue) !important;
    color: var(--blue) !important;
  }

  &.icon {
    color: var(--blue-light);
  }
`;
