import { createSlice } from "@reduxjs/toolkit";

const answersSlice = createSlice({
  name: "answersData",

  initialState: {
    answersList: [],
    answersFetched: false,
  },

  reducers: {
    setAnswersList: (state, action) => {
      const { payload } = action;
      state.answersList = payload;
    },
    setAnswersFetched: (state, action) => {
      const { payload = false } = action;
      state.answersFetched = payload;
    },
  },
});

export const {
  actions: { setAnswersList, setAnswersFetched },
  reducer: answersReducer,
} = answersSlice;
