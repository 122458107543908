import React, { useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { compose, space, flexbox, layout, color } from "styled-system";
import { Menubar as PRStyledMenubar } from "primereact/menubar";
import { Menu as PRMenu } from "primereact/menu";
import Div from "@components/Div";
import { H1, H4 } from "@components/Heading";
import { Button as PRButton } from "@components/Button";
import Icon from "@components/Icon";
import SFBLogo from "@assets/logo.svg";
import { breakpoints } from "@utils/breakpoints";
import Sidemenu from "@components/Sidebar/Sidemenu";
import { ROUTES } from "@utils/constant";
import { RenderNotifications } from "./utils";

const { tabletLandscape, mobile } = breakpoints;

const breakpoint = {
  tablet: `(min-width: ${mobile}px) and (max-width: ${tabletLandscape}px)`,
  mobile: `(max-width: ${mobile}px)`,
};

const MenuBar = styled(PRStyledMenubar)`
  position: sticky;
  z-index: 999;
  top: 0;
  left: 0;
  border: none;
  background-color: var(--white);
  border-radius: 0px;
  ${compose(space, flexbox, layout, color)};
  .p-menubar-button {
    display: none !important;
  }
  .p-menubar-end {
    & > div {
      display: flex;
      flex-direction: row;
    }
  }
  &.p-menubar.p-component {
    padding: var(--icon-fs-milli) var(--md-spacing);
    box-shadow: var(--box-shadow);

    @media ${breakpoint.mobile} {
      .p-menubar-start {
        width: 100%;

        > div {
          justify-content: space-between;
          flex-direction: row-reverse;
        }
      }
    }
  }
  @media ${breakpoint.mobile} {
    background-color: var(--primary);
  }
`;

export const Button = styled(PRButton)`
  width: 36px;
  height: 36px;
  background-color: var(--transparent) !important;
  border: ${({ $isDetailedPage }) =>
    $isDetailedPage
      ? "1px solid var(--text) !important"
      : "1px solid var(--border-line) !important"};
  justify-content: center;
  align-items: center;
  &:focus {
    box-shadow: none;
  }
  @media ${breakpoint.mobile} {
    border: 1px solid var(--text) !important;
  }
`;

const HamburgerButton = styled(Button)`
  border: 1px solid var(--text) !important;
  display: none;
  margin-right: 0;
  @media ${breakpoint.tablet} {
    border: 1px solid var(--text) !important;
    display: flex;
  }
  @media ${breakpoint.mobile} {
    display: flex;
  }
`;

const Menu = styled(PRMenu)`
  top: 58px !important;
  width: 100%;
  border-radius: 0px;
  background-color: var(--primary) !important;
  padding: var(--xl-spacing) 0 var(--regular-spacing) 0;
  box-shadow: none !important;
  border-top: 1px solid var(--text) !important;
  &.p-menu-overlay {
    position: fixed;
  }
  span {
    color: var(--text) !important;
    line-height: var(--lh-base) !important;
    font-size: var(--fs-milli);
    &.icon::before {
      font-size: var(--fs-h5);
    }
  }
  a {
    &:focus {
      box-shadow: none !important;
    }
    &:hover {
      background-color: var(--primary-hover) !important;
    }
  }
`;

const NotificationsMenu = styled(PRMenu)`
  width: 275px !important;
  padding: 0 !important;
  top: 64px !important;
  box-shadpw: var(--box-shadow);
  a {
    padding: 0 !important;
    &:focus {
      box-shadow: none !important;
    }
    &:hover {
      background-color: var(--white) !important;
    }
  }
  span {
    width: 100%;
  }
`;

const Logo = styled.img`
  ${compose(space, flexbox, layout, color)};
`;

const Header = ({ menuItems = [] }) => {
  const dispatch = useDispatch();
  const [notifications] = useState([]);
  const menu = useRef(null);
  const notificationRef = useRef(null);
  const navigate = useNavigate();
  const [showMenuCloseIcon, setShowMenuCloseIcon] = useState(false);
  const [showSideMenu, setShowSideMenu] = useState(false);
  const handleDisplaySideMenu = () => {
    setShowSideMenu(!showSideMenu);
  };
  const [userName] = useState("");

  const handleGoBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    const fetchNotifications = async () => {};
    fetchNotifications();
  }, [dispatch]);

  const { headerTitle = "", isDetailPage = "" } = useSelector(
    (state) => state.headerReducer
  );

  const { customer = "" } = useSelector((state) => state.customerReducer);

  const start = (
    <Div display="flex">
      <HamburgerButton mr="var(--md-spacing)" onClick={handleDisplaySideMenu}>
        <Icon
          className="icon ph-text-align-left-bold"
          color="var(--text)"
          fontSize="var(--icon-fs-milli)"
        />
      </HamburgerButton>
      <Div display={["none", "none", "flex", "flex"]}>
        {isDetailPage && (
          <Button mr="var(--md-spacing)" onClick={handleGoBack} $isDetailedPage>
            <Icon
              className="icon ph-arrow-left-bold"
              color="var(--text)"
              fontSize="var(--fs-h5)"
            />
          </Button>
        )}
      </Div>
      <H1 display={["none", "flex", "flex", "flex"]}>{headerTitle}</H1>
      <Logo
        src={SFBLogo}
        alt="icon"
        display={["flex", "none", "none", "none"]}
      />
    </Div>
  );

  const end = (
    <Div alignItems="center">
      <H4
        display={["none", "flex", "flex", "flex"]}
        fontWeight="var(--semibold-weight) !important"
      >
        {customer}
      </H4>
    </Div>
  );

  const handleMenu = () => {
    setShowMenuCloseIcon(!showMenuCloseIcon);
  };

  const notificationItems = RenderNotifications(notifications);

  const handleRedirection = (obj) => {
    const { redirectTo, isExternalPage } = obj;
    if (isExternalPage) {
      window.open(redirectTo, "_blank");
    } else if (redirectTo === ROUTES.LOGIN.URL) {
      localStorage.clear();
      window.location.reload();
    } else {
      navigate(redirectTo);
    }
  };

  const items = menuItems.map((o) => ({
    ...o,
    command: () => handleRedirection(o),
  }));

  items[0].label = userName || "";

  return (
    <>
      <Sidemenu
        onClose={handleDisplaySideMenu}
        display={["none", "flex", "flex", "none"]}
        visibility={showSideMenu}
      />
      <MenuBar start={start} end={end} width={1} display="flex" />
      <Menu
        model={items}
        popup
        ref={menu}
        onShow={handleMenu}
        onHide={handleMenu}
      />
      <NotificationsMenu
        model={notificationItems}
        popup
        ref={notificationRef}
      />
    </>
  );
};

Header.propTypes = {
  setPageTitle: PropTypes.string,
  menuItems: PropTypes.array,
};

export default Header;
