import { combineReducers } from "redux";
import { authReducer } from "./authReducer";
import { headerReducer } from "./headerReducer";
import { sidebarReducer } from "./sidebarReducer";
import { customerReducer } from "./customerReducer";
import { advisorReducer } from "./advisorReducer";
import { casesReducer } from "./casesReducer";
import { answersReducer } from "./answersReducer";
import { surveyReducer } from "./surveyReducer";

export const rootReducer = combineReducers({
  authReducer,
  headerReducer,
  sidebarReducer,
  customerReducer,
  advisorReducer,
  casesReducer,
  answersReducer,
  surveyReducer,
});
