import { createSlice } from "@reduxjs/toolkit";

const advisorSlice = createSlice({
  name: "advisorData",

  initialState: {
    advisorDetails: {},
  },

  reducers: {
    setAdvisorDetails: (state, action) => {
      const { payload } = action;
      state.advisorDetails = payload;
    },
  },
});

export const {
  actions: { setAdvisorDetails },
  reducer: advisorReducer,
} = advisorSlice;
