import config from "@src/config";
import { AwsClient } from "aws4fetch";

const authRefreshService = async () => {
  const awsCredentials = JSON.parse(localStorage.getItem("aws-credentials"));
  if (awsCredentials) {
    const { accessKeyId, secretAccessKey, sessionToken } = awsCredentials;

    const aws = new AwsClient({
      accessKeyId,
      secretAccessKey,
      sessionToken,
      region: "eu-north-1",
      service: "execute-api",
    });

    const request = await aws.sign(`${config.BASE_URL}auth-refresh`, {
      method: "GET",
    });
    const response = await fetch(request);

    return response.json();
  }
};

export default authRefreshService;
