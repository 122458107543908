import lazyWithRetry from "@shared/lazyWithRetry";

const Home = lazyWithRetry(() => import("@pages/Home/Home"));
const Search = lazyWithRetry(() => import("@pages/Search/Search"));
const Thankyou = lazyWithRetry(() => import("@pages/Thankyou/Thankyou"));
const Login = lazyWithRetry(() => import("@pages/auth/Login"));

import { SVLANG, ROUTES } from "@utils/constant";

export const PublicRoutes = [
  {
    path: ROUTES.LANDING_PAGE.URL,
    title: SVLANG.login,
    component: Login,
  },
  {
    path: ROUTES.LOGIN.URL,
    title: SVLANG.login,
    component: Login,
  },
];

export const PrivateRoutes = [
  {
    path: ROUTES.HOME.URL,
    title: SVLANG.home,
    component: Home,
    showSidemenu: ROUTES.HOME.showSidemenu,
  },
  {
    path: ROUTES.SEARCH.URL,
    title: SVLANG.search,
    component: Search,
    showSidemenu: ROUTES.SEARCH.showSidemenu,
  },
  {
    path: ROUTES.THANKYOU.URL,
    title: SVLANG.thankyou,
    component: Thankyou,
    showSidemenu: ROUTES.THANKYOU.showSidemenu,
  },
];

const RoutesList = [...PublicRoutes, ...PrivateRoutes];

export default RoutesList;
