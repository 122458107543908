import { createSlice } from "@reduxjs/toolkit";

const sidebarDataSlice = createSlice({
  name: "sidebarData",

  initialState: {
    sidebarData: [],
    pageName: "",
  },

  reducers: {
    setSidebarMenuItem: (state, action) => {
      const { payload } = action;
      state.sidebarMenuItem = payload;
    },
    setPageName: (state, action) => {
      const { payload } = action;
      state.pageName = payload;
    },
  },
});

export const {
  actions: { setSidebarMenuItem, setPageName },
  reducer: sidebarReducer,
} = sidebarDataSlice;
