import { createSlice } from "@reduxjs/toolkit";

const customerSlice = createSlice({
  name: "customerData",

  initialState: {
    customerDetails: {},
    customer: "",
  },

  reducers: {
    setCustomerDetails: (state, action) => {
      const { payload } = action;
      state.customerDetails = payload;
    },
    setCustomer: (state, action) => {
      const { payload } = action;
      state.customer = payload;
    },
  },
});

export const {
  actions: { setCustomer, setCustomerDetails },
  reducer: customerReducer,
} = customerSlice;
