import styled from "styled-components";
import {
  color,
  compose,
  space,
  layout,
  typography,
  display,
  flexbox,
} from "styled-system";
import { getColor } from "@utils/utils";

export const H1 = styled("h1")`
  ${compose(space, typography, color, display)};
  font-size: var(--fs-h1);
  color: var(--text);
  font-weight: var(--semibold-weight);
  line-height: var(--lh-h1);
  text-transform: ${({ upper }) => (upper ? "uppercase" : "none")};
`;

export const H2 = styled("h2")`
  ${compose(space, typography, color, display)};
  font-size: var(--fs-h2);
  color: var(--text);
  font-weight: var(--normal-weight);
  line-height: var(--lh-h2);
  text-transform: ${({ upper }) => (upper ? "uppercase" : "none")};
`;

export const H3 = styled("h3")`
  ${compose(space, typography, color, display)};
  font-size: var(--fs-h3);
  color: ${(props) => getColor(props)};
  font-weight: var(--normal-weight);
  line-height: var(--lh-h3);
  text-transform: ${({ upper }) => (upper ? "uppercase" : "none")};
`;

export const H4 = styled("h4")`
  ${compose(space, typography, color, display)};
  font-size: var(--fs-h4);
  color: var(--text);
  font-weight: var(--normal-weight);
  line-height: var(--lh-h4);
  text-transform: ${({ upper }) => (upper ? "uppercase" : "none")};
`;

export const H5 = styled("h5")`
  ${compose(space, typography, color, display, layout, flexbox)};
  font-size: var(--fs-h5);
  color: ${(props) => getColor(props)};
  font-weight: var(--normal-weight);
  line-height: var(--lh-h5);
  text-transform: ${({ upper }) => (upper ? "uppercase" : "none")};
`;

export const H6 = styled("h6")`
  ${compose(space, typography, color, display, layout)};
  font-size: var(--fs-h6);
  color: ${(props) => getColor(props)};
  font-weight: var(--bold-weight);
  line-height: var(--lh-h6);
  text-transform: ${({ upper }) => (upper ? "uppercase" : "none")};
`;
