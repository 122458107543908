import React from "react";
import { useIntl } from "react-intl";
import styled from "styled-components";
import { CaptionSemiboldText, CaptionText } from "@components/Text";
import Label from "@components/Label";
import { getLabelColor } from "@utils/utils";
import Div from "@components/Div";
import { ROUTES } from "@utils/constant";
import { useNavigate } from "react-router-dom";
import { LOW, HIGH, MEDIUM } from "../DataTable/Constants";
import messages_sv from "@translation/sv.json";
import { format } from "date-fns";

export const SVLANG = messages_sv;

const StyledDiv = styled(Div)`
  &:hover {
    background-color: rgba(235, 50, 53, 0.05);
  }
`;

export const getLabelTranslation = (value) => {
  switch (value) {
    case LOW:
      return SVLANG.label_low;
    case MEDIUM:
      return SVLANG.label_medium;
    case HIGH:
      return SVLANG.label_high;
    default:
      return SVLANG.label_low;
  }
};

export const RenderNotifications = (notifications = []) => {
  const { messages } = useIntl();
  const navigate = useNavigate();

  const redirectToNotification = () => {
    navigate(ROUTES.NOTIFICATIONS.URL);
  };

  notifications.sort(function (a, b) {
    return new Date(b.CreatedDate) - new Date(a.CreatedDate);
  });

  return [
    {
      label: (
        <Div maxHeight={525} overflowY="auto" overflowX="hidden">
          {Array.isArray(notifications) &&
            notifications.map((obj, index) => {
              const { CreatedDate, Subject, Priority } = obj;
              return (
                <StyledDiv
                  p={"var(--regular-spacing)"}
                  key={index}
                  borderBottom="1px solid var(--border-line)"
                  onClick={redirectToNotification}
                >
                  <Div
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                    alignItems="center"
                    mb="var(--sm-spacing)"
                  >
                    <CaptionText color="var(--grey-dark) !important">
                      {format(new Date(CreatedDate), "Y-MM-dd")}
                    </CaptionText>
                    {Priority && (
                      <Label
                        bg={getLabelColor(Priority)}
                        width={80}
                        height={20}
                      >
                        {getLabelTranslation(Priority)}
                      </Label>
                    )}
                  </Div>

                  <CaptionSemiboldText
                    mb={1}
                    textTruncate
                    fontWeight="var(--bold-weight) !important"
                  >
                    {Subject}
                  </CaptionSemiboldText>
                </StyledDiv>
              );
            })}
        </Div>
      ),
    },
    {
      label: (
        <Div
          display="flex"
          textAlign="center"
          py={"var(--regular-spacing)"}
          boxShadow="var(--box-shadow)"
        >
          <CaptionSemiboldText
            color="var(--blue-light)"
            fontWeight="var(--bold-weight) !important"
            onClick={redirectToNotification}
          >
            {messages.label_show_all}
          </CaptionSemiboldText>
        </Div>
      ),
    },
  ];
};
